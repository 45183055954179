@import "../../styles/variables";
@import "../../styles/mixins";

.footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent !important;
  padding: 1rem 1.5rem;
  width: 100%;
  font-size: 0.8rem;

  .up-btn {
    padding: 0.6rem 1rem;
    color: #ffff;
    background-color: black;
    border: none;
    font-family: $font-family-text;
    font-weight: bold;
    letter-spacing: 0.3rem;
    cursor: pointer;
  }

  .privacy-text {
    font-family: $font-family-text;
    align-self: flex-end;
    color: #c8bba7;
    line-height: 1.5rem;
  }
  span {
    color: $color-accent;
  }
    a {
      color: $color-accent;
    }
}
.contact-footer {
  span {
    color: $color-accent;
  }

  a {
    color: $color-accent;
  }

  .privacy-text {
    font-family: $font-family-text;
    align-self: flex-end;
    color: #c8bba7;
    line-height: 1.5rem;
  }
}
@include phone {
  .footer {
    font-size: 0.6rem;
  }
  .privacy-text {
    margin-right: 0.5rem;
  }
}
