@import "../../styles/variables";
@import "../../styles/layout";

.channel-card {
  display: flex;
  flex-direction: row;
  margin: 0rem 1rem;
  width: 48%;
  height: fit-content;
  border: 1.5px solid $border-brown;
  background-color: #000;
}

.odd-card {
  margin-top: 5rem;
  margin-right: 0rem;

  &:nth-child(2) {
    margin-top: 10.5rem;
  }
}

.even-card {
  margin-left: 0;

  &:not(:first-child) {
    margin-top: 2.5rem;
  }
}

.channel-card-section {
  width: 50%;
  border-right: 1.5px solid $border-brown;
  font-weight: bold;
}

.channel-card-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 70%;
}

.channel-card-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30%;
  border-top: 1.5px solid $border-brown;
  font-family: $font-family-text;
  font-size: 0.9rem;
  letter-spacing: 0.5rem;
  color: $color-accent;
  text-align: center;
  font-weight: bold !important;
  line-height: 1.2rem;
}

.channel-card-text {
  width: 65%;
  padding: 1.5rem;
  font-size: 0.9rem;
  font-family: $font-family-text;
  line-height: 2rem;
  color: $color-text-2;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #0000;
  scrollbar-track-color: $border-black;
}

.channel-card-text::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #0000;
}

.channel-card-text::-webkit-scrollbar {
  width: 6px;
  background-color: #0000;
}

.channel-card-text::-webkit-scrollbar-thumb {
  background-color: $border-black;
  border: 2px solid $border-black;
}

// .channel-card-text {
//   width: 65%;
//   padding: 1.5rem;
//   font-size: 0.9rem;
//   font-family: $font-family-text;
//   line-height: 2rem;
//   color: $color-text-2;
// }

@media (max-width: 1350px) {
  .channel-card {
    width: 100%;
  }

  .odd-card {
    margin-top: 2rem;
    margin-left: 3rem;
  }

  .even-card {
    margin-right: 3rem;
    margin-top: 2rem;
  }
}

@include tablet {
  .channel-card {
    flex-direction: column;
    min-height: 400px;
    margin-top: 4rem;
    width: 95%;
  }
  .channel-card-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 30%;
    border: none;
    border-bottom: 1.5px solid $border-brown;
    padding: 1rem;
  }

  .channel-card-icon-container {
    display: flex;
    flex-wrap: wrap;
    width: 30%;
  }

  .channel-card-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-family-text;
    font-size: 0.9rem;
    border-top: none;
    flex-grow: 1;
    letter-spacing: 0.5rem;
    color: $color-accent;
    text-align: center;
  }

  .channel-card-icon-container {
    height: 100%;
  }

  .channel-card-title {
    height: 100%;
    border-top: none;
  }

  .channel-card-text {
    width: 100%;
    font-size: 0.9rem;
  }
}

@include tablet {
  .channel-card-text {
    font-size: 0.85rem;
  }

  .channel-card-icon {
    height: 45px;
  }
}
