@import "../../components/contactForm/contactForm.scss";
@import "../../styles/mixins";
.contact-container {
  display: flex;
  height:100vh
}
.contact-side {
  width: 36.5%;
  height: 100%;
  grid-template-columns: 1fr;
  background-image: url(../../assets/BG/gradient_2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.hr-contact{
  box-sizing: border-box;
  margin: 0;
  outline: none;
  border: none;
  position:relative;
  right: 420px;
  top: 50px;
  z-index: 0;
  width: 100%;
  height: 1px;
  color: $border-gray-3;
  background-color: $border-gray-3;
}
.contact-side-details {
  padding: 0 0 0 25%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr;
  width: 95%;
  color: $bg-cream-1;
}
.contact-side-details-title {
  font-family: $font-family-title;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-top: 13.5rem;
}
.contact-side-details-text {
  font-family: $font-family-text;
  font-size: 0.8rem;
  letter-spacing: 0.06rem;
  line-height: 2rem;
  color: $border-gray-1

  //opacity: 30%;
}
.contact-main {
  width: 63.5%;
  height: 100%;
  background-color: $bg-cream-1;
}
.contact-main-presentation{
  padding-top: 14.5rem;
  padding-left: 11%;
}
.contact-main-presentation-intro {
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  font-family: $font-family-title;
  line-height: 2rem;
  font-weight: normal;
  color: $border-black;
}
.contact-main-presentation-title {
  font-family: $font-family-title;
  font-size: 3.3rem;
  letter-spacing: 0.1rem;
  margin-top: 0.5rem;
}
.contact-main-presentation-text {
  padding-left: 0.5rem;
  width: 62%;
  font-family: $font-family-text;
  font-size: 0.74rem;
  line-height: 2rem;
  letter-spacing: 0.01rem;
  margin-top: 1.5rem;
}
.contact-footer {
  color: $color-text-2;
  font-size: 0.7rem;
  font-family: $font-family-text;
  position: fixed;
  left: 0;
  bottom: 25px;
  right: 0;
  width: 36.5%;
}
.accent {
  color: $color-accent;
}
@include large-tablet {
    .hr-contact{
      right: 195px;
  }
  .contact-main-presentation-title {
    font-size: 2.5rem;
  }
  .contact-main-presentation-intro {
    font-size: 1rem;
  }
  .contact-main-presentation-text {
    width: 70%;
  }
  .contact-side {
    padding-left: 5%;
  }
  .contact-side-details {
    padding-left: 5%;
  }
}
@include tablet {
  .contact-main-presentation-title {
    font-size: 1.8rem;
  }
  .contact-main-presentation-intro {
    font-size: 0.8rem;
  }
  .contact-main-presentation-text {
    font-size: 0.5;
  }
  .contact-side {
    padding-left: 2%;
  }
  .contact-container {
    min-height: 1200px;
  }
  .contact-footer {
    color: $color-text-2;
    font-size: 0.7rem;
    font-family: $font-family-text;
    position: fixed;
    left: 0;
    bottom: 25px;
    right: 0;
    width: 36.5%;
  }
}
@include phone {
  .contact-container {
    display: block;
  }
  .hr-contact{
    position: relative;
    width: 300px;
    right: 0;
    top: 420px;
  }
  .contact-side {
    width: 100%;
    padding-top: 1rem;
  }
  .contact-main {
    width: 100%;
    height: 60%;
  }
  .contact-main-presentation{
    padding: 1rem 1rem;
  }
  .contact-side-details {
    padding-left: 2rem;
  }
  .contact-side-details-title {
    margin-top: 5rem;
    font-size: 1.5rem;
  }
  .contact-side-details-text {
    font-size: 0.8rem;
  }
  .contact-side {
    height: 40%;
  }
  .contact-main-presentation-title {
    font-size: 1.6rem;
  }
  .contact-main-presentation-text {
    width: 100%;
  }
  .form-input-group {
    width: 90%;
    padding: 0;
    margin-left: 0;
  }
  .form-input {
    width: 98%;
  }
  .contact-footer {
    display: none;
  }
}
