@import "../../styles/variables";
@import "../../styles/layout";

.case-mosaic {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 12rem;
  height: auto;
}

.mosaic-sec {
  width: 50%;
  display: flex;
}

.img-border {
  border: 1px solid $border-black;
  border-radius: 5px;
}

.mosaic-img {
  width: 100%;
  height: 100%;
  border: 5px solid #000;
  border-radius: 5px;
  background-color: #000;
}

.case-number {
  position: absolute;
  top: 0;
  font-family: $font-family-text;
  color: #e9dfcf;
  margin-right: 15%;
  font-weight: bolder;
}

.case-number-odd {
  position: absolute;
  left: 0;
  margin-right: 0%;
  margin-left: 15%;
}

.mosaic-sec-1 {
  position: relative;
  align-items: flex-end;
  justify-content: flex-end;
}

.mosaic-img-container-1 {
  position: relative;
  display: flex;
  align-items: flex-end;
  max-width: 100%;
  margin-right: 15%;
}

.mosaic-img-container-1-odd {
  margin-right: 0%;
  margin-left: 15%;
}

.mosaic-img-container-2 {
  width: 100%;
  margin-bottom: 15%;
}

.mosaic-sec-3 {
  position: relative;
}

.mosaic-img-container-3 {
  position: absolute;
  margin-top: 3.5rem;
  max-height: 50vh;
  width: 80%;
  margin-bottom: 15%;
  right: -20%;
}

.mosaic-img-container-3-odd {
  right: 0%;
  left: -20%;
}

.mosaic-card-container {
  position: relative;
  box-sizing: content-box;
  margin-left: 10%;
  width: 100%;
  min-height: 450px;
}

.mosaic-card-container-odd {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-right: 10%;
  margin-left: 0%;
}

.case-card-border {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: auto;
  max-width: 420px;
  //width: 80%;
  background-color: $bg-cream-1;
  top: -200px;
  border: 1px solid #c8bba7;
  padding: 0.5rem;
  border-radius: 5px;
  z-index: 3;
}

.case-card {
  border: 1px solid #c8bba7;
  padding: 2rem 2.5rem;
  height: 100%;
  width: 100%;
}

.case-card-category {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;

  .category-icon {
    align-self: center;
    padding-right: 0.5rem;
    height: 30px;
    filter: invert(1);
  }

  .category-text {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: $color-typo-1;
  }
}

.case-card-title {
  font-family: $font-family-title;
  font-size: 2rem;
  color: $color-typo-1;
  height: auto;
  padding: 0.5rem 0;
}

.case-card-arrow-icon {
  width: 30px;
  padding-top: 10%;
}

.case-card-text {
  font-family: $font-family-text;
  font-size: 1rem;
  color: $color-text-1;
  line-height: 2em;
}

.case-card-bottom {
  font-family: $font-family-text;
  font-weight: bold;
  color: $color-accent;
  letter-spacing: 0.9rem;
  font-size: 0.9rem;
  padding: 0.5rem 0;
}

.case-card-click {
  font-size: 14px;
  font-family: $font-family-text;
  color: $color-typo-1;
}

@include desktop {
  .case-card-border {
    width: 90%;
  }
}

@include large-tablet {
  .mosaic-sec {
    width: 100%;
  }

  .case-number {
    top: 0;
    margin-right: 0;
    left: 10%;
  }

  .case-number-odd {
    right: 10%;
    left: auto;
    margin-right: 0;
  }

  .mosaic-img-container-1 {
    align-items: flex-end;
    height: -20vh;
    max-width: 35%;
    z-index: 2;
  }

  .mosaic-img-container-2 {
    position: absolute;
    margin-bottom: 15%;
    margin-left: 10%;
    max-width: 50%;
    top: -20%;
    z-index: 1;
  }

  .mosaic-img-container-2-odd {
    margin-left: 0%;
    margin-right: 10%;
  }

  .mosaic-sec-2 {
    position: relative;
    min-height: 380px;
    width: 100%;
  }

  .mosaic-sec-3 {
    display: none;
  }

  .mosaic-card-container {
    display: flex;
    justify-content: flex-end;
    min-height: initial;
    margin-bottom: 15rem;
    position: relative;
    z-index: 5;
  }

  .mosaic-card-container-odd {
    display: flex;
    justify-content: flex-start;
  }
  .case-card-border {
    width: 70%;
    top: -250px;
  }
}

@include tablet {
  .case-card-border {
    height: auto;
    width: 70%;
    min-width: initial;
  }

  .case-card {
    padding: 1rem 1rem;
  }

  .case-card-title {
    font-size: 1.7rem;
    margin-bottom: 5%;
    height: auto;
  }

  .case-arrow-icon {
    width: 25px;
    height: 15%;
    margin-bottom: 0.5rem;
  }
}

@include phone {
  .case-card-border {
    width: 90%;
    top: -270px;
  }

  .mosaic-img-container-1 {
    height: 15vh;
    max-width: 40%;
    margin-right: 5%;
  }

  .mosaic-img-container-2 {
    max-width: 60%;
    top: -15%;
  }

  .case-card-title {
    font-size: 1.4rem;
  }

  .case-card-text {
    font-size: 0.9rem;
    line-height: 1.5em;
  }

  .case-card-bottom {
    font-size: 0.8rem;
    letter-spacing: 0.5rem;
  }
}

.mosaic-sec-1-odd {
  order: 1;
}
.mosaic-sec-2-odd {
  order: 2;
  justify-content: flex-start;
}
.mosaic-sec-3-odd {
  order: 3;
}

.mosaic-sec-4-odd {
  order: 4;
}
@include large-tablet {
  .mosaic-sec-1-odd {
    order: 2;
    justify-content: flex-end;
  }
  .mosaic-sec-2-odd {
    order: 1;
  }
}

@media (max-width: 2200px) {
  .none-desktop {
    display: none;
  }
}

@include large-tablet {
  .none-desktop {
    display: block;
  }
}
