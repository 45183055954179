@import "../../styles/variables";
@import "../../styles/mixins";

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent !important;
  padding: 1.5rem 1.5rem;
  width: 100%;
  position: absolute;
  z-index: 510;
  overflow: hidden;
}

.nav-fixed {
  position: fixed;
}

.nav-logo {
  flex-basis: 20%;

  .nav-logo-img {
    height: 35px;
  }

  .nav-logo-img-white {
    filter: invert(1);
  }
}

.nav-language-icon {
  width: 25px;
  margin-left: 10rem;
  margin-right: 2rem;
}

.nav-hamburger {
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex-basis: 20%;
  flex-direction: row;
  .nav-hamburger-cont {
    float: right;
    cursor: pointer;
  }
  .nav-hamburger-icon {
    width: 25px;
    margin-bottom: 4px;
  }

  .nav-hamburger-icon-white {
    filter: invert(1);
  }
}

.nav-menu {
  display: flex;
  .menu-link {
    list-style: none;
    margin: 0 3rem;
  }

  .nav-link {
    text-decoration: none;
    font-family: $font-family-text;
    font-weight: bold;
    font-size: 1.1rem;
    color: $color-text-2;
    letter-spacing: 0.5rem;
    cursor: pointer;

    &:hover {
      color: $color-accent;
      transition: all 0.5s;
    }

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }
  }
}

@include large-tablet {
  .nav-menu {
    .menu-link {
      margin: 0 1.3rem;
    }
    .nav-link {
      font-size: 0.8rem;
    }
  }
}

@include tablet {
  .nav-menu {
    .menu-link {
      display: none;
    }
  }

  .nav-logo {
    .nav-logo-img {
      height: 25px;
    }
  }

  .nav-hamburger {
    flex-basis: 10%;
    .nav-hamburger-icon {
      width: 20px;
    }
  }
}
