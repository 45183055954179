//fonts
$font-family-title: "Agrandir-GrandMedium", -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Roboto", "Oxygen";

$font-family-text: "Roboto Mono", monospace, "Roboto", "Oxygen";

//background colors
$bg-black: #0000;
$bg-cream-1: #fffaf3;
$bg-cream-2: #fcf5eb;

//border colors
$border-cream: #e9dfcf;
$border-black: #323232;
$border-brown: #796b58;
$border-gray-1:#D0D0D0;
$border-gray-2:#676767;
$border-gray-3:#E9DFCF;
//font colors
$color-typo-1: #362918;
$color-typo-2: #dcd4d9;
$color-text-1: #796b58;
$color-text-2: #c8bba7;
$color-accent: #ff4501;
