@mixin phone {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin large-tablet {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin big-desktop {
  @media (max-width: 1800px) {
    @content;
  }
}
