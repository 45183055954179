@import "../../components/mosaic/mosaic.scss";
.bg-black {
  padding: 5px;
  background-color: #000;
  outline: $border-gray-2 solid 1px;
  border-radius: 5px;
}
.work-header {
  padding-top: 12rem;
  padding-bottom: 6rem;
  margin-bottom: 10rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
}
.work-header-description {
  font-family: $font-family-title;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
}
.work-header-description-star {
  justify-self: center;
  padding-bottom: 1.5rem;
}
.work-header-description-intro {
  font-size: 1.5rem;
  color: $color-typo-1;
  justify-self: center;
}
.work-header-description-title {
  box-sizing: content-box;
  font-size: 4rem;
  justify-self: center;
  color: $color-typo-1;
}
.work-header-description-paragraph {
  justify-self: center;
  font-family: $font-family-text;
  font-size: 1.1rem;
  color: $color-text-1;
  line-height: 2rem;
}

.work-header-navBar {
  justify-self: center;
  margin-top: 1.5rem;
  display: flex;
  width: 75%;
  height: 5rem;
  border: 1px solid $border-gray-1;
}
.navBar-text {
  padding: 2rem;
  height: 100%;
  width: 20%;
  align-self: center;
  justify-content: center;
  justify-self: center;
  font-family: $font-family-title;
  font-size: 1.1rem;
  color: $color-typo-1;
}
.navBar-input {
  font-family: $font-family-text;
  color: $color-text-1;
  font-size: 1.1rem;
  width: 50%;
  height: 100%;
  background-color: $bg-cream-1;
  border: none;
  :focus-visible {
    border: none;
    outline: none;
  }
}
.filter {
  border-left: 1px solid $border-gray-1;
  height: 100%;
  width: 30%;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  justify-self: center;
  font-family: $font-family-text;
  color: $color-accent;
  font-weight: 800;
  padding: 1%;
  font-size: 0.8rem;
  letter-spacing: 0.5rem;
  ::after {
    content: " \25BC";
    font-size: 11px;
  }

  border: none;
  background-color: transparent;
}

.grid {
  display: grid;
}

.doubleCol {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.relative-sm-up {
  position: relative;
  bottom: 200px;
  z-index: 400;
}
.background-gradient-black {
  background-image: url(../../assets/BG/gradient_1_flipped.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: #000;
  .wave-container:last-child {
    padding-top: 2px;
    margin-bottom: 10%;
  }
}
.background-color-cream-2 {
  background-color: $bg-cream-1;
}
@include tablet {
  .doubleCol {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0;
    justify-content: center;
  }

  .navBar-input {
    width: 0;
  }
  .filter {
    width: 50%;
    letter-spacing: 0.5rem;
  }
  .navBar-text {
    display: flex;
    justify-content: center;
    font-size: 0.9rem;
    width: 50%;
  }
  .work-header-description-intro {
    font-size: 1.2rem;
  }
  .work-header-description-title {
    font-size: 3rem;
  }
  .work-header-description-paragraph {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
@include phone {
  .work-header-description-intro {
    font-size: 0.9rem;
  }
  .work-header {
    margin-bottom: 3rem;
  }
  .work-header-description-title {
    font-size: 2rem;
  }
  .work-header-description-paragraph {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }
  .work-header-navBar {
    height: 3rem;
    width: 95%;
  }
  .filter {
    font-size: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navBar-text {
    padding: 1rem 0.4rem;
  }
}
