//fonts
@font-face {
  font-family: "Agrandir-GrandMedium";
  src: url(./assets/fonts/Agrandir-GrandMedium.otf);
}
// En los assets no venia roboto mono regular
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;700&family=Roboto:wght@100&display=swap");

//imports
@import "./styles/variables";

@import "./styles/normalize";

@import "./views/work/work_view.scss";

@import "./views/contact/contact.scss";

@import "./views/caseSingle/caseSingle.scss";