@import "../../styles/variables";
@import "../../styles/mixins";

.menu {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #000;
  width: 100%;
  left: -100%;
  transition: 850ms;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 500;
  flex-direction: column;

  hr {
    width: 100%;
    height: 1px;
    border-width: 0;
    margin: 0;
    color: rgba(216, 216, 216, 0.3);
    background-color: rgba(216, 216, 216, 0.3);
  }

  .menu-container {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 11rem 0 15rem 0;
  }

  .menu-social-media-icons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 15%;
    padding: 1rem 0 1rem 0;
  }

  .menu-social-media-icon {
    height: 25px;
  }

  .menu-social-media-icon-be {
    height: 20px;
  }

  .menu-links {
    display: flex;
    flex-direction: column;
    width: 55%;
    justify-content: space-between;
    order: 2;
  }

  .menu-link {
    list-style: none;
    padding-left: 6rem;
    font-family: $font-family-title;
    font-size: 4rem;
    cursor: pointer;
    :hover {
      color: white;
      transition: 400ms;
    }
  }

  .menu-link-selected {
    border-left: 3px solid $bg-cream-1;
  }

  .menu-link-number {
    font-size: 1rem;
    margin-left: auto;
    margin-right: 3rem;
    font-family: $font-family-text;
    display: flex;
    flex-direction: row;
    width: auto;
    align-items: center;
  }

  .menu-link-number-selected {
    color: $color-accent;
  }

  .menu-link-text {
    display: flex;
    flex-direction: row;
    width: auto;
    align-items: center;
    color: rgba(255, 250, 243, 0.3);
  }

  .menu-link-selected-icon {
    position: relative;
    top: -10px;
    align-self: flex-start;
    height: 25px;
  }

  .menu-details {
    margin: 1rem 0 1rem 6rem;
    width: 30%;
    display: flex;
    flex-direction: column;
    color: $bg-cream-1;
    justify-content: space-between;
    order: 3;
  }

  .menu-details-title {
    font-family: $font-family-title;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .menu-details-text {
    display: flex;
    flex-direction: column;
    font-family: $font-family-text;
    font-size: 0.9rem;
    line-height: 2rem;
    color: #d0d0d0;
  }

  .footer {
    position: relative;
    display: flex;

    background: transparent !important;
    padding: 1rem 1.5rem;
    width: 100%;

    .privacy-text {
      font-family: $font-family-text;
      color: #d0d0d0;
      margin: auto;
    }
    span {
      color: $color-accent;
    }
  }
}

.menu.active {
  left: 0;
  transition: 850ms;
}

@include big-desktop {
  .menu {
    .menu-container {
      margin: 7rem 0 9rem 0;
    }
    .menu-link {
      font-size: 3rem;
    }
  }
}

@include desktop {
  .menu {
    .menu-details {
      margin: 1rem 0 1rem 2rem;
    }
  }
}

@include large-tablet {
  .menu {
    .menu-link {
      padding-left: 2rem;
    }

    .menu-details {
      margin-left: 0.5rem;
    }

    .menu-link-number {
      margin-right: 1rem;
    }
  }
}

@include tablet {
  .menu {
    .menu-container {
      flex-direction: column;
      margin: 2rem 0 1rem 0;
    }

    .menu-responsive-container {
      display: flex;
      flex-direction: row;
      order: 2;
      width: 100%;
      margin-top: 3rem;
    }

    .menu-social-media-icons {
      width: 10%;
      align-items: center;
    }

    .menu-links {
      width: 100%;
    }

    .menu-link {
      margin-top: 2rem;
      font-size: 2.5rem;
    }

    .menu-details {
      order: 2;
      margin: 0rem 0 1rem 2rem;
      width: 80%;
      display: flex;
      flex-direction: column;
    }

    .menu-details-title {
      font-size: 1.2rem;
      margin-top: 1rem;
    }

    .menu-details-text {
      font-family: $font-family-text;
      font-size: 0.7rem;
      line-height: 1.5rem;
    }
  }
}

@include phone {
  .menu {
    .menu-link {
      font-size: 2rem;
    }
  }
}
