.contact-form{
 display: flex;
 justify-content: left;
 margin-top: 0.5rem;
padding-left: 11%;
}
.form-input-group{
position: relative;
z-index: 3;
margin-top: 1.6rem;
margin-left: 0.3rem;
display: grid;
grid-template-columns: 1fr;
justify-content: space-between;
align-items: center;
}
.form-input{
margin-top: 0.5rem;
height: 1.5rem;
padding: 1rem 0.7rem;
font-family: $font-family-text;
font-size: 0.8rem;
width: 17.5rem;
resize: none;
border-radius: 0;
border: none;
outline: $border-gray-3 solid 1px ;
}
.form-textarea{
    height: 7rem;
}
.form-arrow{
    height: 1.7rem;
    width: 1.7rem;
    justify-self: left;
   
}
.round-border{
    border: $border-gray-3 solid 1px;
    padding: 0 0 1rem 3rem;
    width: 16rem;
    background-color: transparent;
    height: 16rem;
    border-radius: 50%;
    align-self: flex-end;
    margin-left: 5%;
}
.form-button{
    display: grid;
    align-items:center;
    justify-content: center;
}
.form-button-text{
    font-family: $font-family-text;
    color: $color-accent;
    font-size: 1.1rem;
    font-weight: 700;
    margin-top: 1.4rem;
    margin-bottom: 0;
    letter-spacing: 0.8rem;
    height: 1.7rem;
  
}
.form-button-helper{
    font-family: $font-family-text;
    font-size: 0.6rem;
    justify-self:  left;
    align-self: flex-start;
    padding: 0;
}

@include large-tablet{
    .contact-form{
        display: block
       }
        .form-button-text{
            font-size: 0.8rem;
            margin-top: 1rem;
        }
        .form-button-helper{
            font-family: $font-family-text;
            font-size: 0.5rem;
            justify-self:  left;
            align-self: flex-start;
            padding: 0;
        }
        .round-border{
        margin-top: 2rem;
        height: 12rem;
        width: 12rem;
         padding: 0 0 1rem 1.5rem ;    }

}
@include tablet{
     .form-button-text{
        font-size: 0.8rem;
    }
}
@include phone{
    padding: 1rem 1rem;
}