@import "../../styles/variables";
@import "../../styles/layout";

.studio-container {
  //background-color: #fffdf6;
  width: 100%;
  min-height: 110vh;
  overflow: hidden;
}

.about-us {
  padding-top: 9rem;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 7rem;

  .about-us-description {
    height: 65%;
    width: 45%;
    display: flex;
    flex-direction: column;
    font-family: $font-family-title;

    .about-us-description-intro {
      font-size: 1.5rem;
      justify-self: flex-end;
      color: $color-typo-1;
      margin-bottom: 1rem;
    }
    .about-us-description-title {
      box-sizing: content-box;
      font-size: 4.5rem;
      width: 850px;
      z-index: 1;
      color: $color-typo-1;
    }

    .about-us-description-paragraph {
      padding-right: 3.5rem;
      font-family: $font-family-text;
      font-size: 1.2rem;
      color: $color-text-1;
      bottom: 0;
      margin-top: 8%;
      line-height: 2rem;
    }
  }

  .about-us-hero {
    display: inline-block;
    position: relative;
    margin-left: auto;
    max-width: 38%;
  }

  .about-us-hero-picture {
    display: flex;
    border: 2px solid $border-cream;
    border-radius: 5px;
    max-height: 100%;
    max-width: 100%;
  }

  .about-us-hero-img {
    width: 100%;
    max-height: 100%;
    border: 5px solid #ffff;
    border-radius: 5px;
  }

  .about-us-hero-seal {
    height: 70px;
    position: absolute;
    top: -30px;
    right: -30px;
  }

  hr {
    margin: 2rem 0;
    width: 100%;
    height: 2px;
    border-width: 0;
    // color: $border-cream;
    // background-color: $border-cream;
  }

  .about-us-details {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;

    .about-us-details-info {
      padding: 0rem 1rem;
      display: flex;
      width: 33%;
      font-family: $font-family-text;
      font-size: 14px;
      color: #676767;
      line-height: 2rem;
    }

    .about-us-details-info:first-child {
      font-family: $font-family-title;
      font-size: 1.3rem;
      color: $color-typo-1;
      width: 34%;
      padding-left: 0;
    }

    .about-us-details-info:last-child {
      padding-right: 0;
    }
  }
}

@include big-desktop {
  .about-us-hero {
    width: 55%;
    max-height: 400px;
    max-width: initial !important;
  }
}

@include desktop {
  .about-us {
    .about-us-description {
      .about-us-description-intro {
        font-size: 1.3rem;
      }
      .about-us-description-title {
        font-size: 3rem;
        width: 600px;
      }

      .about-us-description-paragraph {
        font-family: $font-family-text;
        font-size: 0.9rem;
      }
    }
  }
}

@include large-tablet {
  .about-us {
    .about-us-description {
      .about-us-description-intro {
        font-size: 1rem;
      }
      .about-us-description-title {
        font-size: 2.5rem;
        width: 500px;
      }

      .about-us-description-paragraph {
        padding-right: 3.5rem;
        font-family: $font-family-text;
        font-size: 0.7rem;
      }
    }
  }
}

@include tablet {
  .about-us {
    padding-top: 2.5rem;
    .about-us-hero {
      width: 100%;
      max-height: 70%;
      .about-us-hero-seal {
        height: 40px;
        top: -15px;
        right: 10px;
      }

      .about-us-hero-picture {
        max-height: initial;
        height: 300px;
      }
    }

    .about-us-description {
      width: 100%;
      height: auto;
      .about-us-description-intro {
        font-size: 0.9rem;
      }
      .about-us-description-title {
        font-size: 2rem;
        box-sizing: border-box;
        width: 100%;
      }

      .about-us-description-paragraph {
        padding-right: 0;
        font-size: 0.8rem;
        margin: 20px 0;
      }
    }
    .about-us-details {
      .about-us-details-info {
        width: 100%;
        padding: 0.5rem 0;
      }
      .about-us-details-info:first-child {
        width: 100%;
        padding: 0.5rem 0;
      }
    }
  }
}

.channels {
  width: 100%;
  background-size: 100% 100%;
}

.channels-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
  width: 100%;

  .channels-title {
    font-size: 4rem;
    font-family: $font-family-title;
    color: #dcd4c9;
    margin: auto;
    text-align: center;
  }

  .channels-star-icon {
    height: 35px;
    margin: auto;
    margin-top: 7rem;
    margin-bottom: 10px;
  }

  .channels-subtitle {
    font-size: 1.2rem;
    font-family: $font-family-text;
    color: $color-text-2;
    margin: auto;
    padding-top: 0.8rem;
  }

  .channels-cards {
    margin-top: 9rem;
    padding-bottom: 9rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
}

@include tablet {
  .channels-container {
    .channels-title {
      font-size: 3rem;
    }

    .channels-cards {
      margin-top: 4rem;
    }

    .channels-star-icon {
      margin-top: 4rem;
    }
  }
}

@include phone {
  .channels-container {
    .channels-title {
      font-size: 2.2rem;
    }

    .channels-subtitle {
      font-size: 1rem;
    }

    .channels-star-icon {
      height: 25px;
    }
  }
}

.contact-card-container {
  margin-top: 5rem;
}
