@import '../../styles/_utils.scss';


.singleCase-mainTopic{
display: grid;
grid-template-columns: 2fr 3fr;
}
.case-work-header{
    padding: 0;
}
.singleCase-mainTopic-mainInfo-doubleImages{
    width:fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.doubleImages-image{
    padding: 2.5%;
img{
    width: 100%;
}
}
.authors-profilePic{
    height: 10rem;
    width: 10rem;
    margin-top: 5rem;
    margin-bottom: 2rem;
}
//SIDE INFO
.singleCase-mainTopic-sideInfo{
    font-family: $font-family-title;
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 23.5% 15% 15% 20%;
}
.singleCase-mainTopic-sideInfo-impactfullMessage{
    line-height: 2.5rem;
    font-size: 1.4rem;
    margin-bottom: 5rem;
}
.singleCase-mainTopic-mainInfo{
 font-family: $font-family-text;
 font-size: 1rem;
 line-height: 3rem;
 padding: 15% 15% 15% 0;
}
.color-accent{
    color: $color-accent;
}
.author-capabilities{
    font-family: $font-family-title;
}
.color-text-2{
    color:$color-text-2;
}
.singleCase-mainTopic-mainInfo-big-img{
    width: 100%;
    image-rendering: optimizeQuality;
}
.singleCase-main-image{
    background-color: snow;
    border:  1px solid $border-cream;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    height: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
   img{
        width: 100%;
        height:100%;
    }
}
.singleCase-mainTopic-mainInfo-big-img{
    margin: 5rem 0;
}
.singleCase-mainTopic-mainInfo-quote{
    display: flex;
    justify-content: center;
    margin: 8% 0 8% 1%;
    font-family: $font-family-title;
    font-size: 1.5rem;
    padding-left: 2%;
    line-height: 2.5rem;
    .author-quote-vline{
        margin: 0.68rem 0 0.85rem 0;
        width: 5px;
    }
    .quote-info{
        padding-left: 2.5%
    }
}
.singleCase-mainTopic-sideInfo-authorsInfo{
    display: flex;
    align-items: center;
    padding: 8%;
    padding-left: 0;
}
.author-description{
    font-size: 1.4rem;
    line-height: 2rem;
    font-family: $font-family-title;
    margin-bottom: 27px;
}
.author-quote{
    display: flex;
    color:$color-text-2;
    font-family: $font-family-text;
    font-size: 1rem;
    padding-left: 0;
    line-height: 2.5rem;
}
.author-quote-vline{
    height: auto;
    margin:  0.9rem 0;
    width: 3px;
    margin-right: 1rem;
    line-height: 0;
    background-color: snow;
}
.skill-list{
    list-style: none;
    font-family: $font-family-text;
    font-size: 1.2rem;
    line-height: 3rem;
    letter-spacing: .25rem;
    font-weight: 700;
}
.case-circle{
    width: 14vw;
    height: 14vw;
    border: 1px solid #d3c6b2;
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.case-circle-text{
    font-family: $font-family-text;
    font-weight: bolder;
    color: $color-accent;
    width: 250%;
    letter-spacing: 0.9rem;
    font-size: 1.5rem;
    text-align: center;
}
.case-arrow-icon{
    padding-bottom: 10%;
    color: snow;
}
.case-circle-link-container{
    margin-bottom: 10rem;
}
//HEADER
.mainCartel{
    border: 2px solid $border-cream;
    display: flex;
    width: 100%;
    margin-bottom: 2rem;
}
.mainCartel-mainSide{
    display: flex;
    width: 85%;
    justify-content: space-between;
    padding: 3% 3% 3% 3%;
}
.caseSingle-header{
    position: relative;
    top: 10rem;
    padding:  0 8%;
    z-index:400
}
.mainCartel-mainSide-intro{
    font-size: 2rem;
    font-family: $font-family-title;
    font-weight:200;
    letter-spacing: 0.1rem;
    margin-bottom: 5px;
}
.mainCartel-mainSide-title{
    display: grid;
    justify-content: center;
    align-items: flex-end;
    padding-top: 1rem;
    font-size: 4.5rem;
    font-family: $font-family-title;
}
.mainCartel-mainSide-title-title{
    width: 100%;
}
.mainCartel-mainSide-text{
    font-family: $font-family-text;
    color: $color-typo-1;
    font-size: 1rem;
    line-height: 2.5rem;
    width: 40%;
    padding-left: 3%;
}
.mainCartel-logoSide{
    border-left: 2px solid $border-cream;
    width: 15%;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    padding: 3% 1%;
}
.caseSingle-mainCartel-typelogo{
    width: 3.5rem;
    height: 3.5rem;
    justify-self: center;
    align-self: center;
}
.mainCartel-logoSide-subtitle{
    padding-left: .8rem;
    padding-top: .8rem;
    font-size: 1rem;
    color: $color-accent;
    letter-spacing: 0.7rem;
    font-family: $font-family-text;
    font-weight: bolder;
    justify-self: center;
    align-self: center;
}
@include large-tablet {
    .singleCase-main-image {
        height: 40vh;
    }
}
@include tablet {
    
    .singleCase-mainTopic-mainInfo-quote{
        padding-left: 0;
    }
    .authors-profilePic{
        height: 8rem;
        width: 8rem;
    }
    .author-quote{
        margin-bottom: 5rem;
        padding: 0 5% 
    }
  .singleCase-mainTopic-sideInfo-impactfullMessage{
      margin-bottom: 1rem;
  }
  .singleCase-mainTopic{
      grid-template-columns: 2fr;
      }
  .singleCase-mainTopic-mainInfo{
      padding: 5%;
      font-size: .9rem
      }
  .singleCase-mainTopic-sideInfo{
      padding: 0%;
      padding-left: 5%;
      padding-top: 15%;
      }
    .singleCase-mainTopic-sideInfo-authorsInfo{
    margin-bottom: 27px;
    padding-left: 8%;
    padding-bottom: 0;
    align-items: flex-end;
    }
    .authorsInfo-top{
        padding-left: 0;
    }
    .author-description{
        padding-left: 5%;
        margin-bottom: 0;
    }
    .authors-profilePic{
        margin-bottom: 0;
        align-self: flex-end;
    }
  .doubleImages-image{
      padding: 1%;
  }
  .mainCartel{
      display: grid;
  }
  .mainCartel-mainSide{
      display: grid;
      padding: 5%;
      width: 100%;
  }
  .mainCartel-mainSide-title{
      width: 100%;
      justify-content: flex-start;
    }
  .mainCartel-mainSide-text{
      width: 100%;
  }
  .mainCartel-logoSide{
      width: 20%;
  }
  .mainCartel-logoSide{
      display: flex;
      width: 100%;
      padding: 5%;
      justify-content: flex-start;
      border-left: none;
      border-bottom: 2px solid $border-cream;
  }
  .case-circle{
    width: 24vw;
    height: 24vw;
}
.singleCase-mainTopic-mainInfo-quote{
    margin-left: 0;
    justify-content: space-between;
    .quote-info{
        padding-left: 25px;
        font-size:1rem ;
    }
}
}
@include phone{
    .singleCase-main-image {
            height: auto;
        }
    .mainCartel-mainSide-intro{
        font-size: 1rem;
        padding-bottom: 5%;
    }
    .mainCartel-mainSide-title{
        font-size: 2rem;
    }
    .mainCartel-mainSide-text{
        font-size: .7rem;
        padding: 0;
    }
    .singleCase-main-image{
        padding: 2%;
    }
    .case-circle{
        width: 34vw;
        height: 34vw;
    }
    .skill-list{
        font-size: 1rem;
    }
}