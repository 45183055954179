@import "../../styles/mixins";
.mosaic {
  display: grid;
  width: 65%;
  height: 10%;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(11, fr);
}
.mosaic-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10rem;
}
.mosaic-item:last-of-type {
  grid-column: 1 / span 5;
  z-index: 100;
  grid-row: 5 / span 5;
}
.mosaic-item:first-of-type {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23vw;
  grid-column: 2 / span 5;
  grid-row: 1 / span 5;
  img {
    width: 100%;
    image-rendering: auto;
    height: 100%;
  }
}

.mosaicCard {
  padding: 2%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  background-color: $bg-cream-1;
}
.cream-border {
  border-style: solid;
  border-width: 1px;
  height: 100%;
  border-color: $border-cream;
  padding: 10% 10% 7% 10%;
}
.mosaicCard-categoryContainer {
  display: flex;
  align-items: center;
  font-family: $font-family-title;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0.1rem;
  color: $color-typo-1;
  img {
    width: 2.5rem;
    height: 2.5rem;
    image-rendering: crisp-edges;
  }
}
.darkMode-icon {
  filter: invert(1);
}

.mosaicCard-category {
  padding: 1% 0 0 3%;
  align-self: center;
  font-family: $font-family-text;
  font-size: 1rem;
}
.mosaicCard-proyectName {
  font-family: $font-family-title;
  color: $color-typo-1;
  font-size: 2rem;
  letter-spacing: 0.05rem;
  margin: 8% 0%;
}
.mosaicCard-arrow {
  height: 13.5%;
  width: 13.5%;
  margin-top: 2%;
}
.mosaicCard-learnMore {
  font-family: $font-family-text;
  padding: 0.5rem 0;
  color: $color-accent;
  margin: 2% 0% 2% 0%;
  letter-spacing: 0.9rem;
  font-size: 0.9rem;
  font-weight: 600;
}
.mosaicCard-click {
  font-family: $font-family-text;
  color: $color-typo-1;
  font-size: 14px;
}
.mosaicCard-click-dark {
  color: snow;
}
.border-gray-2 {
  border-style: solid;
  border-width: 1px;
  height: 100%;
  padding: 10% 10% 7% 10%;
  border-color: $border-gray-2;
}
.color-white {
  color: $color-typo-2;
}
.black-background {
  background-color: #000;
}
@include large-tablet {
  .mosaic {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    height: 95%;
    margin: 5%;
  }
  .mosaic-item:last-of-type {
    grid-column: 1 / span 10;
    grid-row: 5 / span 5;
  }
  .mosaic-item:first-of-type {
    height: 58vh;
    grid-column: 2 / span 11;
    grid-row: 1 / span 5;
  }
  .mosaicCard-categoryContainer {
    font-size: 0.5rem;
    img {
      width: 2rem;
      height: 2rem;
    }
  }
  .mosaicCard-proyectName {
    font-size: 1.2rem;
  }
  .mosaicCard-learnMore {
    font-size: 0.6rem;
    letter-spacing: 0.5rem;
  }
  .mosaicCard-click {
    font-size: 0.6rem;
  }
}
@include tablet {
  .mosaic {
    width: 100%;
  }
  .mosaicCard {
    margin: 0;
  }
  .mosaicCard-category {
    font-size: 1.7rem;
  }
  .mosaicCard-proyectName {
    font-size: 2.8rem;
  }
  .mosaicCard-learnMore {
    font-size: 1.2rem;
    letter-spacing: 1.1rem;
  }
  .mosaicCard-click {
    font-size: 1.1rem;
  }
}
@include phone {
  .mosaic-item:first-of-type {
      height: auto;
    }
  .mosaicCard {
    .mosaicCard-categoryContainer {
      font-size: 0.8rem;
      img {
        height: 2rem;
        width: 2rem;
      }
    }
    .mosaicCard-proyectName {
      font-size: 1.5rem;
    }
    .mosaicCard-arrow {
      margin-top: 2%;
    }
    .mosaicCard-learnMore {
      font-size: 0.7rem;
      letter-spacing: 0.7rem;
    }
    .mosaicCard-click {
      font-size: 0.6rem;
    }
  }
}
