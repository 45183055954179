@import "../../styles/variables";
@import "../../styles/layout";

.home-container {
  background-color: #fffaf3;
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

hr {
  margin: 1rem 0;
  width: 100%;
  height: 1px;
  border-width: 0;
}

.hero-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 3rem;
  z-index: 5;

  .hero {
    height: 700px;
    display: flex;
    margin-bottom: 10rem;
    margin-top: 1.5rem;
    flex-wrap: wrap;
  }

  .rectangle {
    position: absolute;
    width: 95%;
    height: 750px;
    border: 2px solid #e9dfcf;
    left: 2.5%;
    top: 150px;
  }

  .hero-title-sec {
    width: 50%;
    display: flex;
    flex-direction: column;
    font-family: $font-family-title;
    color: $color-typo-1;
    padding: 5rem 0;
  }

  .hero-intro-text {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  .hero-title {
    font-size: 4.1rem;
  }

  .hero-bottomText {
    font-family: $font-family-text;
    font-weight: 1rem;
    width: 75%;
    margin-top: 2rem;
    color: $color-text-1;
    line-height: 2rem;
  }

  .hero-img-sec {
    width: 50%;
    position: relative;
  }

  .hero-img {
    max-width: 75%;
    max-height: 75%;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .hero-lines {
    position: absolute;
    max-width: 130%;
    top: 53%;
    left: 53%;
    transform: translate(-50%, -50%);
  }

  .brush-1 {
    position: absolute;
    max-width: 130%;
    top: 50%;
    left: 10%;
    transform: translate(-50%, -50%);
  }

  .brush-2 {
    position: absolute;
    max-width: 140%;
    top: 30%;
    left: 95%;
    transform: translate(-50%, -50%);
  }

  .hero-container-glass {
    position: absolute;
    width: 70%;
    min-height: 15%;
    background-color: rgba(200, 200, 200, 0.25);
    backdrop-filter: blur(10px);
    z-index: 20;
    top: 70%;
    left: 60%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem 1rem 1.5rem;
    font-family: $font-family-text;
    border: 1px solid #ffff;
    color: #ffff;
    line-height: 1.5rem;

    > p {
      text-shadow: 2px 2px 2px black;
    }
  }

  .hero-circle-link-container {
    display: flex;
    position: relative;
    width: 100%;
    top: -4%;
    //left: 0%;

    .hero-circle-text {
      position: absolute;
      top: 55%;
      left: 33%;
      transform: translate(-50%, -50%);
      font-family: $font-family-text;
      color: $color-accent;
      letter-spacing: 0.9rem;
      font-size: 16px;
      text-align: center;
      font-weight: bold;
    }

    .hero-arrow-icon {
      position: absolute;
      top: 40%;
      left: 13%;
      transform: translate(-50%, -50%);
      width: 2vw;
    }

    .circle {
      width: 300px;
      height: 300px;
      border: 1px solid #e9dfcf;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
    }
  }

  @include desktop {
    .hero-title {
      font-size: 3.5rem;
    }
  }

  @include large-tablet {
    .hero-circle-link-container {
      .hero-circle-text {
        letter-spacing: 0.6rem;
        font-size: 14px;
      }

      .circle {
        width: 200px;
        height: 200px;
      }
    }

    .hero-img {
      max-width: 90%;
      max-height: 90%;
    }
    .hero-container-glass {
      top: 60%;
    }

    .hero-title {
      font-size: 3rem;
    }
    .hero-intro-text {
      margin-bottom: 1rem;
      font-size: 1rem;
    }
  }

  @include tablet {
    .hero {
      flex-direction: column;
      min-height: 1050px;
    }

    .hero-title-sec {
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .hero-intro-text {
      font-size: 1.3rem;
      text-align: center;
    }

    .hero-title {
      font-size: 3rem;
      text-align: center;
    }

    .rectangle {
      min-height: 1100px;
      width: 90vw;
      left: 5%;
    }

    .hero-circle-link-container {
      left: 0;
      justify-content: center;
      margin-top: 3rem;
      .hero-circle-text {
        left: 50%;
        top: 60%;
      }

      .hero-arrow-icon {
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 3.5vw;
      }

      .circle {
        width: 150px;
        height: 150px;
      }
    }
    .hero-bottomText {
      width: 100%;
      padding: 0 1rem;
    }

    .hero-img-sec {
      width: 100%;
      margin-top: 10%;
      min-height: 360px;
    }
    .hero-img {
      max-width: 90%;
      max-height: 110%;

      position: absolute;
      z-index: 10;
    }

    .hero-lines {
      width: 150%;
      left: 53%;
    }

    .brush-1 {
      max-width: 130%;
      left: 10%;
      top: 25%;
    }

    .brush-2 {
      max-width: 100%;
      left: 65%;
      top: 20%;
    }

    .hero-container-glass {
      width: 80%;
      min-height: 15%;

      top: 70%;
      left: 50%;
    }
  }

  @include phone {
    .hero {
      min-height: 870px;
      padding-top: 1rem;
    }

    .hero-img-sec {
      width: 100%;
      min-height: 250px;
      margin-top: 0px;
    }

    .hero-img {
      max-width: 85%;
      max-height: 110%;

      position: absolute;
      z-index: 10;
    }

    .rectangle {
      min-height: 850px;
    }

    .hero-intro-text {
      font-size: 1rem;
    }

    .hero-title {
      font-size: 2rem;
    }

    .hero-bottomText {
      padding: 0 1.5rem;
      line-height: 1.5rem;
      font-size: 0.7rem;
    }

    .hero-lines {
      width: 120%;
    }

    .brush-1 {
      max-width: 120%;
      top: 60%;
      left: 30%;
    }

    .brush-2 {
      max-width: 100%;
    }

    .hero-container-glass {
      width: 95%;
      min-height: 13%;
      font-size: 0.8rem;
      top: 75%;
      left: 50%;
    }

    .hero-circle-link-container {
      margin-top: 2rem;

      .hero-circle-text {
        font-size: 12px;
        width: 100%;
      }

      .hero-arrow-icon {
        top: 30%;
        width: 25px;
      }

      .circle {
        width: 125px;
        height: 125px;
      }
    }
  }
}

@include tablet {
  .hero-container {
    padding-top: 1.5rem;
  }
}

.hero-bottom {
  display: flex;
  flex-direction: column;
  padding-bottom: 8rem;

  .hero-bottom-img-container {
    display: flex;
    border: 1px solid #c8bba7;
    border-radius: 5px;
    max-height: 100%;
    max-width: 100%;
    z-index: 5;
  }

  .bottom-hero-img {
    max-width: 100%;
    max-height: 100%;
    border: 5px solid #ffff;
    border-radius: 5px;
  }

  .hero-bottom-text-container {
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    margin-top: 2rem;
    line-height: 2.5rem;
    color: $color-typo-1;
  }
  .hero-bottom-text-highlight {
    width: 33%;
    font-size: 22px;
    font-family: $font-family-title;
  }

  .hero-bottom-text {
    width: 33%;
    font-size: 14px;
    font-family: $font-family-text;
    padding: 0 1rem;
  }
  //PENDIENTE DE NO ELIMINAR ESTE CIRCULO QUE LO VAS A NECESITAR
  .hero-circle-link-container {
    display: flex;
    width: 33%;
    cursor: pointer;
    height: 13vh;

    .hero-circle-text {
      width: 80%;
      top: 100%;
      left: 70%;
      line-height: 2rem;
      font-family: $font-family-text;
      text-align: initial;
      //font-weight: bold;
    }

    li {
      font-size: 14px;
      font-family: $font-family-text;
      color: #796b58;
      letter-spacing: initial;
      font-weight: initial;
    }

    .hero-arrow-icon {
      position: absolute;
      top: 30%;
      left: 35%;
      transform: translate(-50%, -50%);

      width: 2vw;
    }

    .circle {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
      width: 18vw;
      height: 18vw;
    }
  }

  @include large-tablet {
    .hero-bottom-text-highlight {
      width: 100%;
    }

    .hero-bottom-text {
      width: 100%;
      padding: 1rem 0;
    }

    .hero-circle-link-container {
      width: 100%;
      height: 10vh;

      .hero-circle-text {
        width: 80%;
        top: 100%;
        left: 112%;
        line-height: 1rem;
        text-align: initial;
      }

      .hero-arrow-icon {
        top: 30%;
        left: 74%;
        width: 3vw;
      }

      .circle {
        left: 80%;
        top: 80%;
        width: 23vw;
        height: 23vw;
      }
    }
  }

  @include tablet {
    .hero-circle-link-container {
      .hero-circle-text {
        width: 70%;
        left: 93%;
        font-size: 14px;
        letter-spacing: 0.5rem;
      }

      li {
        font-size: 12px;
      }

      .hero-arrow-icon {
        top: 30%;
        left: 59%;
        width: 3vw;
      }

      .circle {
        left: 65%;
        top: 80%;
        width: 23vw;
        height: 23vw;
      }
    }
  }

  @include phone {
    hr {
      margin: 2rem 0;
      position: relative;
      top: -120px;
    }
    .hero-circle-link-container {
      .hero-circle-text {
        width: 70%;
        left: 83%;
        top: 110%;
        font-size: 14px;
        letter-spacing: 0.5rem;
      }

      li {
        font-size: 12px;
      }

      .hero-arrow-icon {
        top: 45%;
        left: 55%;
        width: 5vw;
      }

      .circle {
        left: 65%;
        top: 75%;
        width: 45vw;
        height: 45vw;
      }
    }
  }
}

//-------------------------CASES ---------------------------------------------------------------

.cases {
  background-color: #000;
  //background: url("../../assets/BG/gradient_1.jpg"), #000;
  background-repeat: no-repeat, repeat;
  width: 100%;
  color: #d3c6b2;
  width: 100%;
  position: relative;
  z-index: 20;
}

.cases-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.cases-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .cases-title {
    font-size: 70px;
    font-family: $font-family-title;
    color: #dcd4c9;
    margin: auto;
    text-align: center;
  }

  .cases-subtitle {
    font-size: 1.6rem;
    font-family: $font-family-text;
    color: $color-text-2;
    margin: auto;
    padding-top: 1rem;
    padding-bottom: 11rem;
  }

  .cases-star-icon {
    height: 30px;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 7rem;
  }

  .cases-mosaic-container {
    padding-bottom: 3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  @include large-tablet {
    .cases-title {
      font-size: 40px;
    }

    .cases-subtitle {
      font-size: 1.2rem;
    }
  }

  @include phone {
    .cases-title {
      font-size: 30px;
    }

    .cases-subtitle {
      font-size: 1rem;
    }
  }
}

//--------------------------------------------CTA-----------------------------------------------------

.cta {
  height: 730px;
  display: flex;
  width: 100%;
  margin-top: 10rem;
  margin-bottom: 6rem;
  position: relative;
  z-index: 300;

  .brush-1 {
    position: absolute;
    width: 120%;
    top: -40%;
    z-index: -1;
    //left: 0%;
  }

  .img-border {
    border: 1px solid #c8bba7;
  }

  .cta-img {
    max-width: 100%;
    height: 100%;
    border: 5px solid #ffff;
    border-radius: 5px;
    background-color: #ffff;
  }
}

.cta-col-1 {
  width: 45%;
  height: 80%;
  position: relative;
}

.cta-row-1-1 {
  height: 50%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4rem;
}

.cta-row-1-2 {
  height: 50%;
  display: flex;
}

.cta-col-2 {
  margin-top: 4rem;
  width: 55%;
  height: 100%;
}

.cta-row-2-1 {
  height: 400px;
  position: relative;
}

.cta-row-2-2 {
  height: 30%;
  display: flex;
}

.cta-title {
  width: 45%;
  margin-left: 8rem;
  padding-top: 2rem;
  font-family: $font-family-title;
  font-size: 4.5rem;
  color: #362918;

  position: relative;
  z-index: 2;
}

.cta-circle-link-container {
  display: flex;
  position: absolute;
  width: 100%;

  li {
    font-size: 14px;
    font-family: $font-family-text;
    color: #796b58;
    letter-spacing: initial;
    font-weight: normal;
    line-height: 2rem;
  }
  .cta-circle-text {
    position: absolute;
    top: 100px;
    left: 110%;
    transform: translate(-50%, -50%);
    font-family: $font-family-text;
    color: $color-accent;
    font-weight: bold;
    letter-spacing: 0.9rem;
    font-size: 16px;
    width: 80%;
    line-height: 2rem;
    z-index: 3;
  }

  .cta-arrow-icon {
    position: absolute;
    top: 50px;
    left: 73%;
    transform: translate(-50%, -50%);
    width: 2vw;
    z-index: 3;
  }

  .circle {
    width: 20vw;
    height: 20vw;
    border: 1px solid #e9dfcf;
    border-radius: 50%;
    display: flex;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -100px;
    z-index: 2;
  }
}

@media (max-width: 1300px) {
  .cta-circle-link-container {
    .cta-circle-text {
      top: 100px;
      left: 110%;
    }
    .cta-arrow-icon {
      top: 60px;
    }
    .circle {
      top: -100px;
      right: -50px;
    }
  }
}

@media (max-width: 1024px) {
  .cta-circle-link-container {
    .cta-circle-text {
      left: 115%;
    }
  }
}

@include large-tablet {
  .cta {
    flex-direction: column;
    height: auto;

    .cta-col-1 {
      width: 100%;
      height: auto;
    }

    .brush-1 {
      right: 20%;
    }

    .cta-row-1-1 {
      height: auto;
      margin-bottom: 1rem;
      width: 100%;
      padding-left: 3rem;
    }

    .cta-row-1-2 {
      height: auto;
      width: 60%;
      display: flex;
    }

    .cta-col-2 {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
    }

    .cta-row-2-1 {
      order: 1;
      position: relative;
      width: 65%;
      height: 500px;
    }

    .cta-row-2-2 {
      order: 2;
      display: flex;
      width: 35%;
      position: relative;
      top: -120px;
      height: 120px;
    }
  }

  .cta-title {
    font-size: 3rem;
    margin-left: 2rem;
  }

  .cta-circle-link-container {
    .cta-circle-text {
      top: 120px;
      left: 130%;
      font-size: 14px;
      line-height: 1rem;
    }

    .cta-arrow-icon {
      position: absolute;
      top: 70px;
      left: 100%;
      width: 40px;
    }

    .circle {
      width: 250px;
      height: 250px;
      right: -150px;
      top: 0px;
    }
  }
}

@include tablet {
  .cta-circle-link-container {
    .cta-circle-text {
      top: 100px;
      left: 130%;
      font-size: 14px;
    }

    .cta-arrow-icon {
      width: 30px;
      top: 60px;
    }

    .circle {
      width: 200px;
      height: 200px;
      right: -40%;
    }
  }
}

@include phone {
  .cta {
    .cta-title {
      width: 250px;
      font-size: 2.5rem;
      margin-left: 1rem;
    }

    .brush-1 {
      position: absolute;
      width: 120%;
      top: -20%;
      z-index: -1;
      right: 12%;
    }

    .cta-row-1-1 {
      height: 250px;
    }

    .cta-row-1-2 {
      height: 200px;
    }
  }

  .cta-circle-link-container {
    .cta-circle-text {
      top: 100px;
      left: 110%;
      font-size: 14px;
      width: 200px;
    }

    .cta-arrow-icon {
      left: 75%;
      top: 50px;
    }
  }

  hr {
    position: relative;
    width: 350px;
  }
}

.contactCard-container {
  background-color: $bg-cream-2;
  padding-top: 5rem;
}
