.wave-container {
  position: relative;
  height: 100px;
  overflow: hidden;
  z-index: 300;
}

.wave-svg {
  height: 100%;
  width: 100%;
}

.wave-path {
  stroke: none;
}

.top-wave {
  top: -2px;
}

.bottom-wave {
  bottom: -2px;
}
