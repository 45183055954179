@import "./mixins";

.main-container {
  padding: 0 20rem 0 20rem;
}

.main-container-top {
  padding-top: 4rem;
}
@include big-desktop {
  .main-container {
    padding-left: 10rem;
    padding-right: 10rem;
  }
}

@include desktop {
  .main-container {
    padding-left: 8rem;
    padding-right: 8rem;
  }
}

@include large-tablet {
  .main-container {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
@include tablet {
  .main-container {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
@include phone {
  .main-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
