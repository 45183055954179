@import "../../styles/variables";
@import "../../styles/layout";

.contact-card {
  margin-bottom: 3rem;
  border: 2px solid $border-cream;
  height: 320px;
  display: flex;
  margin-right: 1.2rem;

  .contact-card-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    border-right: 2px solid $border-cream;
    padding: 0rem 6rem;
    position: relative;
    color: $color-typo-1;
  }
  .contact-card-introduction {
    font-family: $font-family-title;
    font-size: 1.3rem;
    padding-bottom: 0.7rem;
  }
  .contact-card-title {
    font-family: $font-family-title;
    font-size: 3.7rem;
  }

  .contact-card-invitation {
    font-family: $font-family-text;
    font-size: 1rem;
    padding-top: 1rem;
  }

  .contact-card-seal {
    height: 110px;
    position: absolute;
    top: -55px;
    left: -55px;
  }

  .contact-card-chat-section {
    display: flex;
    flex-direction: column;
    padding: 4rem 3rem;
    justify-content: flex-end;
    cursor: pointer;
    line-height: 1.5rem;
  }

  .contact-card-arrow-icon {
    width: 40px;
  }

  .contact-card-chat-title {
    font-family: $font-family-text;
    color: $color-accent;
    font-size: 1.2rem;
    margin: 1rem 0 0.5rem 0.5rem;
    letter-spacing: 0.5rem;
    font-weight: bold;
  }

  .contact-card-chat-subtitle {
    font-family: $font-family-text;
    color: $color-text-1;
    font-size: 1rem;
    margin-left: 0.5rem;
  }
}
.color-white {
  color: $color-typo-2;
}
.black-background {
  background-color: #000;
}
@include desktop {
  .contact-card {
    .contact-card-info {
      padding: 0rem 3rem;
    }

    .contact-card-title {
      font-size: 2.5rem;
    }

    .contact-card-introduction {
      font-size: 1.2rem;
    }
    .contact-card-invitation {
      font-size: 0.9rem;
    }

    .contact-card-chat-section {
      padding: 4rem 2rem;
    }

    .contact-card-arrow-icon {
      width: 35px;
    }

    .contact-card-chat-title {
      font-size: 0.9rem;
      margin: 0.8rem 0 0.3rem 1.5rem;
    }

    .contact-card-chat-subtitle {
      font-family: $font-family-text;
      color: $color-text-1;
      font-size: 0.8rem;
      margin-left: 0.5rem;
    }
  }
}

@include tablet {
  .contact-card {
    margin-bottom: 5rem;
    height: auto;
    display: flex;
    flex-direction: column;
    width: 100%;

    .contact-card-info {
      display: flex;
      flex-direction: column;

      height: 60%;
      width: 100%;
      padding: 3rem 2rem;
      border-bottom: 2px solid $border-cream;
      border-right: none;
    }
    .contact-card-introduction {
      font-size: 1rem;
    }
    .contact-card-title {
      font-size: 1.8rem;
    }

    .contact-card-invitation {
      font-size: 0.7rem;
    }

    .contact-card-seal {
      height: 90px;
      position: absolute;
      top: -35px;
      left: 70%;
    }

    .contact-card-arrow-icon {
      width: 35px;
    }

    .contact-card-chat-section {
      padding: 2rem 2rem;
      width: 100%;
      height: 40%;
    }

    .contact-card-chat-title {
      font-size: 0.9rem;
    }

    .contact-card-chat-subtitle {
      font-size: 0.7rem;
    }
  }
}

@include phone {
}
